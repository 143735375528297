import { Blocker } from "react-router";
import { useTranslation } from "react-i18next";
import { useTheme } from "~/utils/theme-provider";

type Props = {
  blocker: Blocker;
};

export function ModalConfirmProceed({ blocker }: Props) {
  const { t } = useTranslation("translation");
  const [theme] = useTheme();

  return (
    <>
      <dialog className="modal" data-theme={theme} id="modalConfirmProceed">
        <div className="modal-box">
          <div className="text-primary m-auto dark:text-secondary flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 48 48"
              className="inline-block w-12 h-12 mb-4 stroke-current"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M2 12a2 2 0 0 1 2-2h40a2 2 0 1 1 0 4H4a2 2 0 0 1-2-2Z"
                clipRule="evenodd"
              />
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M18 6v4a2 2 0 1 1-4 0V5.2C14 3.663 15.16 2 17.067 2h13.866C32.84 2 34 3.663 34 5.2V10a2 2 0 1 1-4 0V6H18Z"
                clipRule="evenodd"
              />
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M7.717 10.02a2 2 0 0 1 2.263 1.697L14.308 42h19.924l3.784-30.248a2 2 0 0 1 3.969.496l-3.851 30.786v.002C37.942 44.577 36.679 46 34.894 46H13.66c-1.773 0-3.016-1.392-3.234-2.898L6.02 12.283a2 2 0 0 1 1.697-2.263Zm26.447 32.522v-.002.002Z"
                clipRule="evenodd"
              />
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M18.586 20.586a2 2 0 0 1 2.828 0L29.9 29.07a2 2 0 0 1-2.828 2.828l-8.485-8.485a2 2 0 0 1 0-2.828Z"
                clipRule="evenodd"
              />
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M29.9 20.586a2 2 0 0 1 0 2.828L21.413 31.9a2 2 0 0 1-2.828-2.828l8.485-8.485a2 2 0 0 1 2.828 0Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <h3 className="text-2xl font-bold mb-2 text-center">
            {t("confirmProceedModal.areYouSure")}
          </h3>
          <form method="dialog">
            {blocker && (
              <>
                <p className="text-center">
                  {t("confirmProceedModal.areYouSureBody")}
                </p>
                {/*<p>{blocker.state}</p>*/}

                <div className="modal-action">
                  <div>
                    <button
                      className="btn btn-primary w-full order-2"
                      onClick={(e) => {
                        // e.preventDefault();
                        if (blocker?.reset) {
                          blocker.reset();
                        }
                      }}
                    >
                      {t("confirmProceedModal.labelNo")}
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn btn-outline w-full btn-primary order-1"
                      onClick={(e) => {
                        e.preventDefault();
                        if (blocker?.proceed) {
                          blocker.proceed();
                        }
                      }}
                    >
                      {t("confirmProceedModal.labelYes")}
                    </button>
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
        <form
          method="dialog"
          className="modal-backdrop fixed left-0 top-0 w-screen h-screen backdrop-blur-xs"
        >
          <button>close</button>
        </form>
      </dialog>
    </>
  );
}
